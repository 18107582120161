import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export default function BusinessCard() {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image="/static/business-hours.png"
          alt="Business Hours"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Business Hours
          </Typography>
          <Typography gutterBottom variant="h6" component="div">
            Everyday: 4:00PM - 11:00PM
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}