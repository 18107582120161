import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Container,
  Button,
  Box,
  Toolbar,
  Drawer,
  ImageList,
  ImageListItem,
} from "@material-ui/core";
import { Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { NavBar } from "./navigation/NavBar";
import { useSelector, useDispatch } from "react-redux";
import { numberWithCommas } from "../../utils/numbersWithComma";
import moment from "moment";
import { Form, Input } from "../../components";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { setTip } from "../../redux/cartReducer/actions";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {
  DayPicker,
  SingleDatePicker,
  isInclusivelyAfterDay,
} from "react-dates";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  setSelectedServiceOption,
  setOrderProcessingDate,
  setSelectedAddress,
} from "../../redux/publicReducer/actions";
import { useHistory, useRouteMatch } from "react-router-dom";
import Select from "react-select";
import { db } from "../../firebase/config";
import VISA from "../../assets/visa.png";
import AMEX from "../../assets/AMEX.png";
import MASTERCARD from "../../assets/mastercard.png";
import {
  setModeOfPayment,
  setPromoCode,
  setTotalDiscount,
} from "../../redux/cartReducer/actions";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { useCookies } from "react-cookie";
import CARDICONS from "../../assets/CARDICONS.png";
import GIFTCARDS from "../../assets/GIFTCARD.png";
import COIN from "../../assets/COIN.png";

import { rewardsService } from "../../services";
import { RewardsAsPayment } from "./rewards";
import { GiftCardAsPayment } from "./giftcards";
import axios from "axios";

import PaymentNested from "./components/PaymentNested";
import { MenuNavigation } from "./components/MenuNavigation";

const serverId = process.env.REACT_APP_SERVER_ID;
const site = process.env.REACT_APP_SITE;
const siteId = process.env.REACT_APP_SITE_ID;
const siteImgSrc = process.env.REACT_APP_IMAGE_SRC;

//--Time Conditions--//
const startHour = 11;
const startTime = 660;
const endHour = 19;
const endTime = 1220;
const endMinutes = 45;

export const Checkout = () => {
  const [openTips, setOpenTips] = useState(false);
  //const [showModal, setShowModal] = useState(true);
  const [discount, setDiscount] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [tax, setTax] = useState();
  const history = useHistory();
  const [openServiceOptions, setOpenServiceOptions] = useState(false);
  const [openUserCards, setOpenUserCards] = useState(false);
  const [tempPromoCode, setTempPromoCode] = useState("");
  const [cookiePayment, setCookiePayment, removeCookiePayment] = useCookies([
    "payment",
  ]);

  const {
    cartItems,
    totalPriceItems,
    tips,
    selectedPayment,
    promoCode,
    orderSession,
  } = useSelector((state) => state.cart);
  const {
    orderProcessingDate,
    orderProcessingType,
    selectedAddress,
    user,
    loading,
  } = useSelector((state) => state.public);
  const dispatch = useDispatch();

  const initialValues = {
    message: "",
  };

  const validationSchema = Yup.object({
    message: Yup.string(),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { watch, setValue } = methods;
  const watchMessage = watch("message");
  // console.log(watchMessage, "MESSAGE");
  const handleSubmit = (d) => {
    // console.log(d);
  };

  const setKitchenMsg = (e) => {
    sessionStorage.setItem("instuctions", e.target.value);
  };

  useEffect(() => {
    //set date as current date
    dispatch(setOrderProcessingDate(moment()));

    const d = new Date();
    const hour = d.getHours(); //get current hour
    const min = d.getMinutes(); //get current min
    const cdate = d.getDate();
    const cyear = d.getFullYear();
    const cmonth = d.getMonth();

    if (
      d.getDate() == cdate &&
      d.getMonth() == cmonth &&
      d.getFullYear() == cyear &&
      (hour > endHour || (hour == endHour && min >= endMinutes))
    ) {
      //alert("Store is closed. You can order for next day");
      dispatch(setOrderProcessingDate(moment(d, "MM-DD-YYYY").add(1, "days")));
    }

    const url =
      "https://ecommv2.servingintel.com/items/tax/" +
      siteId +
      "?server_id=" +
      serverId;
    axios
      .get(url)
      .then((response) => {
        setTax(
          parseFloat(parseFloat(response.data[0].TaxPercent / 100) / 100) / 100
        );
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    // console.log("pc:" + promoCode);
    if (cookiePayment?.payment?.id) {
      dispatch(setModeOfPayment(cookiePayment.payment));
    }
    if (cookiePayment?.tips) {
      dispatch(setTip(cookiePayment.tips));
    }
    if (cookiePayment.message) {
      setValue("message", cookiePayment.message);
    }
    if (cookiePayment.promoCode) {
      dispatch(setPromoCode(cookiePayment.promoCode));
      setTempPromoCode(cookiePayment.promoCode);
    }
  }, []);

  //console.log(cookiePayment, "COOKIE");
  const getCookie = (key) => {
    var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
    return keyValue ? keyValue[2] : null;
  };

  useEffect(() => {
    // if (user?.address) {
    //   dispatch(setSelectedAddress(user.address));
    // }
    const address = getCookie("sic_address");
    if (address) {
      dispatch(setSelectedAddress(JSON.parse(address)));
    }
  }, []);

  useEffect(() => {
    if (selectedPayment) {
      setCookiePayment("payment", selectedPayment, { path: "/" });
    } else {
      removeCookiePayment("payment", { path: "/" });
    }
    if (tips) {
      setCookiePayment("tips", tips, { path: "/" });
    } else {
      removeCookiePayment("tips", { path: "/" });
    }

    if (promoCode) {
      setCookiePayment("promoCode", promoCode, { path: "/" });
    } else {
      removeCookiePayment("promoCode", { path: "/" });
    }

    if (watchMessage) {
      setCookiePayment("message", watchMessage, { path: "/" });
    } else {
      removeCookiePayment("message", { path: "/" });
    }
  }, [selectedPayment, tips, watchMessage, promoCode]);

  useEffect(() => {
    if (tempPromoCode || promoCode) {
      setDiscount(0);
      setDiscountPercentage(0);
      const tpc =
        tempPromoCode == ""
          ? promoCode != ""
            ? promoCode
            : "coupon"
          : promoCode;
      const url =
        "https://ecommv2.servingintel.com/payment/coupon/" +
        serverId +
        "/" +
        siteId +
        "/" +
        tpc;
      //const url = "/servingintel_api/public/items/all";
      axios
        .get(url)
        .then((response) => {
          if (response.data.length == 0) {
            dispatch(setPromoCode(""));
            //alert("Invalid Coupon");
          } else {
            const dept = response.data.department;
            const percent = response.data.discount_percent;
            const discount = response.data.discount;
            const subTotal = response.data.subTotal;

            setDiscountPercentage(parseFloat(percent * 100));

            if (dept !== "") {
              //by deparment
              let totalDiscountPrice = 0;
              cartItems.forEach((item) => {
                if (item.Department == dept) {
                  totalDiscountPrice =
                    parseFloat(totalDiscountPrice) +
                    parseFloat((item.DefaultPrice / 100) * item.quantity);
                }
              });

              if (totalDiscountPrice > 0) {
                //applicable items
                if (discount > 0) {
                  // direct amount discount
                  if (totalPriceItems >= subTotal) {
                    setDiscount(parseFloat(discount));
                  } else {
                    //dispatch(setPromoCode(""));
                    //alert("Total item(s) price did not meet the minimum required for this coupon which is $"+subTotal+".")
                  }
                } else {
                  //percentage discount
                  if (totalPriceItems >= subTotal) {
                    setDiscount(parseFloat(totalDiscountPrice * percent));
                  } else {
                    //dispatch(setPromoCode(""));
                    //alert("Total item(s) price did not meet the minimum required for this coupon which is $"+subTotal+".")
                  }
                }
              } else {
                dispatch(setPromoCode(""));
                //alert("Coupon is not applicable to the existing Check Item(s).")
              }
            } else {
              //all items
              setDiscount(parseFloat(totalPriceItems * percent));
            }
          }
        })
        .catch((error) => {});
    }
  }, [totalPriceItems]);

  useEffect(() => {
    dispatch(setTotalDiscount(discount));
  }, [discount]);

  const applyCoupon = () => {
    dispatch(setPromoCode(tempPromoCode));
    setDiscount(0);
    setDiscountPercentage(0);

    const url =
      "https://ecommv2.servingintel.com/payment/coupon/" +
      serverId +
      "/" +
      siteId +
      "/" +
      tempPromoCode;
    //const url = "/servingintel_api/public/items/all";
    axios
      .get(url)
      .then((response) => {
        if (response.data.length == 0) {
          dispatch(setPromoCode(""));
          alert("Invalid Coupon");
        } else {
          const dept = response.data.department;
          const percent = response.data.discount_percent;
          const discount = response.data.discount;
          const subTotal = response.data.subTotal;

          setDiscountPercentage(parseFloat(percent * 100));

          if (dept !== "") {
            //by deparment
            let totalDiscountPrice = 0;
            cartItems.forEach((item) => {
              if (item.Department == dept) {
                totalDiscountPrice =
                  parseFloat(totalDiscountPrice) +
                  parseFloat((item.DefaultPrice / 100) * item.quantity);
              }
            });

            if (totalDiscountPrice > 0) {
              //applicable items
              if (discount > 0) {
                // direct amount discount
                if (totalPriceItems >= subTotal) {
                  setDiscount(parseFloat(discount));
                } else {
                  dispatch(setPromoCode(""));
                  alert(
                    "Total item(s) price did not meet the minimum required for this coupon which is $" +
                      subTotal +
                      "."
                  );
                }
              } else {
                //percentage discount
                if (totalPriceItems >= subTotal) {
                  setDiscount(parseFloat(totalDiscountPrice * percent));
                } else {
                  dispatch(setPromoCode(""));
                  alert(
                    "Total item(s) price did not meet the minimum required for this coupon which is $" +
                      subTotal +
                      "."
                  );
                }
              }
            } else {
              dispatch(setPromoCode(""));
              alert("Coupon is not applicable to the existing Check Item(s).");
            }
          } else {
            //all items
            setDiscount(parseFloat(totalPriceItems * percent));
          }
        }
      })
      .catch((error) => {});
  };

  return (
    <React.Fragment>
      <MenuNavigation />
      <NavBar />
      <Container
        style={{
          position: "relative",
          height: "90vh",
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <Typography
          variant="subtitle1"
          style={{ fontWeight: 800 }}
          gutterBottom
        >
          MY ORDER{" "}
          <Button
            style={{
              textTransform: "none",
              color: "gray",
              fontSize: "0.8rem",
            }}
            onClick={() => history.push("/menu")}
          >
            Add More
          </Button>
        </Typography>
        <Box height="1rem" />
        <Grid container spacing={1}>
          {cartItems.length === 0 && (
            <Typography
              style={{ textAlign: "center", width: "100%", color: "#bebebe" }}
              variant="h6"
            >
              NO ITEMS IN CHECK!
            </Typography>
          )}
          {cartItems.map((item) => {
            const modifiers = item.modifiers.sort(
              (a, b) => parseInt(a.ModifierIndex) - parseInt(b.ModifierIndex)
            );
            return (
              <React.Fragment key={item.ItemID}>
                <Grid item xs={2}>
                  <Box display="flex" justifyContent="center">
                    <img
                      src={
                        item.FileName && item.FileName.length > 0
                          ? siteImgSrc + item.FileName
                          : site + "/default.jpg"
                      }
                      style={{ width: "100%", borderRadius: "10px" }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="subtitle2">{item.ItemName}</Typography>
                  <Typography variant="caption">
                    $ {numberWithCommas(item.DefaultPrice / 100)}
                  </Typography>
                  {item.modifiers ? (
                    <Typography variant="subtitle2">Add-On:</Typography>
                  ) : null}
                  {item.modifiers
                    ? modifiers.map((mod) => (
                        <>
                          <Typography variant="subtitle2">
                            {mod.ItemDescription} - ${" "}
                            {numberWithCommas(mod.DefaultPrice / 100)}
                          </Typography>
                        </>
                      ))
                    : null}
                </Grid>
                <Grid item xs={3}>
                  <Grid container justifyContent="flex-end">
                    <Box
                      border="1px solid #cecece"
                      borderRadius="5px"
                      padding="1px"
                      display="flex"
                      alignItems="center"
                    >
                      <RemoveIcon
                        fontSize="small"
                        onClick={() => {
                          if (item.quantity > 1) {
                            const newCartItems = cartItems.map((cartItem) => {
                              if (cartItem.ItemID === item.ItemID) {
                                return { ...item, quantity: item.quantity - 1 };
                              }

                              return cartItem;
                            });

                            dispatch({
                              type: "SET_CART",
                              payload: newCartItems,
                            });
                          } else {
                            const filterCartItems = cartItems
                              .map((i) => i)
                              .filter((j) => j !== item);
                            dispatch({
                              type: "SET_CART",
                              payload: filterCartItems,
                            });
                          }
                        }}
                      />
                      <Box margin="0rem 6px">{item.quantity}</Box>
                      <AddIcon
                        fontSize="small"
                        onClick={() => {
                          const newCartItems = cartItems.map((i) => {
                            if (i === item) {
                              return { ...i, quantity: i.quantity + 1 };
                            }
                            return i;
                          });

                          dispatch({ type: "SET_CART", payload: newCartItems });
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
        <Box height="1rem" />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Typography style={{ fontWeight: 800, textAlign: "right" }}>
                Subtotal
              </Typography>
              <Typography>$ {numberWithCommas(totalPriceItems)}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              {promoCode && discount > 0 ? (
                <>
                  <Typography style={{ fontWeight: 800, textAlign: "right" }}>
                    Promo Code{" "}
                    {discountPercentage != 0
                      ? "(-" + discountPercentage + "%)"
                      : ""}
                    {promoCode}
                    {/* <Box display="flex" alignItems="center"> */}
                    <RemoveCircleIcon
                      style={{ color: "red" }}
                      fontSize="small"
                      onClick={() => {
                        dispatch(setPromoCode(""));
                        setTempPromoCode("");
                      }}
                    />
                    {/* </Box> */}
                  </Typography>
                  <Typography style={{ "font-weight": "600" }}>
                    -$ {numberWithCommas(discount)}
                  </Typography>
                </>
              ) : null}
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography style={{ fontWeight: 800 }}>
                Sales Tax ({parseFloat(tax * 100)}%)
              </Typography>
              <Typography>
                ${" "}
                {numberWithCommas(
                  parseFloat((totalPriceItems - discount) * tax)
                )}
              </Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography style={{ fontWeight: 800 }}>Total</Typography>
              <Typography>
                ${" "}
                {numberWithCommas(
                  parseFloat(totalPriceItems - discount) +
                    parseFloat((totalPriceItems - discount) * tax)
                )}
              </Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography style={{ fontWeight: 800, textAlign: "right" }}>
                Tip
                <Button
                  style={{
                    textTransform: "none",
                    marginLeft: 5,
                    color: "gray",
                    fontSize: "0.8rem",
                  }}
                  onClick={() => setOpenTips(true)}
                >
                  Change
                </Button>
              </Typography>
              <Typography>$ {numberWithCommas(tips)}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography style={{ fontWeight: 800 }}>Amount Due</Typography>
              <Typography>
                ${" "}
                {numberWithCommas(
                  parseFloat(tips) +
                    parseFloat(totalPriceItems - discount) +
                    parseFloat((totalPriceItems - discount) * tax)
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Typography gutterBottom style={{ fontWeight: 800 }}>
          Promo Code
        </Typography>
        <Box display="flex" position="relative">
          <input
            style={{
              width: "100%",
              padding: "1rem",
              border: "1px solid #bebebe",
              fontSize: "1.1rem",
            }}
            placeholder="Enter coupon code"
            value={tempPromoCode}
            onChange={(e) => setTempPromoCode(e.target.value)}
          />
          <Button
            id="coupon_btn"
            style={{ position: "absolute", right: "5px", top: "10px" }}
            onClick={() => {
              applyCoupon();
            }}
          >
            Apply
          </Button>
        </Box>
        <br />
        <Typography gutterBottom style={{ fontWeight: 800 }}>
          Message to Kitchen
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            {/* <TextareaAutosize
              style={{ width: "100%" }}
              minLength={10}
              maxLength={300}
              rowsMin={3}
              rowsMax={3}
              cols={50}
            /> */}
            <Form provider={methods} onSubmit={handleSubmit}>
              <Input
                onChange={setKitchenMsg}
                variant="outlined"
                name="message"
                placeholder="Enter special instructions"
                autoComplete="off"
              />
            </Form>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Typography style={{ fontWeight: 800 }} variant="subtitle1">
            Selected Service Option<span style={{ color: "red" }}> *</span>
          </Typography>
          <Button
            style={{
              textTransform: "none",
              marginLeft: 5,
              color: "#000",
              padding: "0px",
              "margin-left": "1rem",
              "font-style": "italic",
            }}
            onClick={() => setOpenServiceOptions(true)}
          >
            Change
          </Button>
        </Grid>
        <Typography>
          {`${orderProcessingType ? "Pickup" : "Dine-In"} - 
          ${
            orderProcessingDate
              ? moment(orderProcessingDate).format("MMMM DD, YYYY h:mm A")
              : "Please Select Date & Time"
          }`}
        </Typography>

        {/* <Typography style={{ fontWeight: 800 }} variant="subtitle1">
          Pick-up is only available at the St. Charles location.
        </Typography> */}

        <br />
        <Grid container>
          <Typography style={{ fontWeight: 800 }} variant="subtitle1">
            Customer Details<span style={{ color: "red" }}> *</span>
          </Typography>
          <Box
            width="100%"
            borderRadius="10px"
            padding="1rem"
            onClick={() => history.push("/select-address")}
            border="1px solid #bebebe"
          >
            {(!selectedAddress && (
              <>
                <Typography variant="caption" style={{ color: "gray" }}>
                  Add Details
                </Typography>
                <Typography style={{ lineHeight: 1.2 }}>
                  {user?.name}
                </Typography>
              </>
            )) || (
              <>
                <Typography style={{ color: "gray" }} variant="caption">
                  Change Details
                </Typography>
                <Typography style={{ lineHeight: 1.2 }}>
                  {selectedAddress.first_name +
                    " " +
                    selectedAddress.last_name +
                    ", " +
                    selectedAddress.email}
                </Typography>

                <Typography style={{ lineHeight: 1.2 }}>
                  {selectedAddress.address +
                    ", " +
                    selectedAddress.city +
                    ", " +
                    selectedAddress.zipCode}
                </Typography>
                <Typography style={{ lineHeight: 1.2 }}>
                  {selectedAddress.phone}
                </Typography>
              </>
            )}
          </Box>
        </Grid>

        <PaymentSelector setOpenUserCards={setOpenUserCards} />
        <Box height="1rem" />
        {selectedPayment && (
          <>
            <Typography style={{ fontWeight: 800 }} gutterBottom>
              Selected Mode of Payment
            </Typography>
            <span>
              <Typography>{selectedPayment}</Typography>
            </span>
          </>
        )}

        <Box height="1rem" />
        <Box
          bottom="0px"
          // position="fixed"
          left="0px"
          width="100%"
        >
          <Button
            style={{
              display: "none",
              padding: "11px 0rem",
              borderRadius: "10px",
              backgroundColor: cartItems.length ? "#ff0000" : "white",
              boxShadow: "none",
              fontSize: "16px",
              fontWeight: "bold",
              color: cartItems.length ? "#fff" : "#000",
              letterSpacing: "1px",
            }}
            fullWidth
            variant="contained"
            disabled={!cartItems.length}
            onClick={() => {
              if (
                ["Gift Card", "Rewards"].includes(selectedPayment) &&
                selectedPayment != ""
              ) {
                rewardsService.handleRewardsPayment(orderSession);
                alert("Order payment success!");
              }
            }}
          >
            Place order
          </Button>
        </Box>
      </Container>
      <TipsDrawer open={openTips} setOpen={setOpenTips} />
      <SelectCreditCardDrawer
        open={openUserCards}
        setOpen={setOpenUserCards}
        tax={tax}
      />
      <ServiceOptionsDrawer
        open={openServiceOptions}
        setOpen={setOpenServiceOptions}
      />

      {/* <Modal
        show={showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Checkout Reminder!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>
            <p>Pick-up is only available at the St. Charles location.</p>
            <p>
              For pickup orders at our new South Elgin location, please call the
              store at 707–754–3377.
            </p>
            <p>Thank You.</p>
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setShowModal(false);
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal> */}
    </React.Fragment>
  );
};

export const ServiceOptionsDrawer = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(moment());
  const [focused, setFocused] = useState(false);
  const [timeConstant, setTimeConstant] = useState([]);
  //const [time, setTime] = useState(moment().add("20", "mins"));
  const { orderProcessingDate, orderProcessingType } = useSelector(
    (state) => state.public
  );

  const updateTime = (e) => {
    //console.log(e, "E");
    // dispatch(setOrderProcessingDate(moment()))
    if (Date.parse(orderProcessingDate) > 0) {
      const timeAndDate = moment(
        moment(orderProcessingDate).format("MMMM DD, YYYY") + " " + e.value
      );
      //console.log(timeAndDate, "TIME AND DATE 1234");

      dispatch(setOrderProcessingDate(timeAndDate));
    } else {
      //alert("Invalid Date");
      const today = new Date();
      const defautTimeAndDate = moment(
        moment(orderProcessingDate).format("MMMM DD, YYYY") + " " + today
      );
      dispatch(setOrderProcessingDate(defautTimeAndDate));
    }
  };

  useEffect(() => {
    if (timeConstant.length > 0) {
      timeConstant.map((time, i) => {
        if (i === 0) {
          updateTime({
            label: time.name,
            value: time.code,
          });
        }
      });
    }
  }, [timeConstant]);

  useEffect(() => {
    var tempTimeConstant = [];
    var d = new Date();
    var selectedDate =
      Date.parse(orderProcessingDate) > 0 ? new Date(orderProcessingDate) : d;
    var hour = d.getHours(); //get current hour
    var min = d.getMinutes(); //get current min
    var cdate = d.getDate();
    var cyear = d.getFullYear();
    var cmonth = d.getMonth();

    var interval = 15; //minutes interval
    var times = []; // time array
    var tt = startTime; // start time
    var ap = ["AM", "PM"]; // AM-PM

    //--round time--//
    var date_r = new Date(d.getTime());

    // date_r.setMinutes(((((date_r.getMinutes() + 7.5) / 15) | 0) * 15) % 60);
    date_r.setMinutes(date_r.getMinutes() + 15);
    //date_r.setHours(date_r.setMinutes(date_r.getMinutes() + 8));
    //--end round time--//

    if (selectedDate) {
      //loop to increment the time and push results in array
      for (var i = 0; tt <= 24 * 60; i++) {
        var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
        var mm = tt % 60; // getting minutes of the hour in 0-55 format
        times[i] =
          ("0" + (((hh + startHour) % 12) + 1)).slice(-2) +
          ":" +
          ("0" + mm).slice(-2) +
          " " +
          ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
        tt = tt + interval;

        if (tt <= endTime) {
          //console.log(hh + "=" + hour);
          //console.log(mm + "=" + (min + 15));
          if (
            selectedDate.getDate() == cdate &&
            selectedDate.getMonth() == cmonth &&
            selectedDate.getFullYear() == cyear
          ) {
            //--set time to current option--//
            let timeOption = new Date(d.getTime());
            timeOption.setHours(hh);
            timeOption.setMinutes(mm);

            if (
              //(hh >= hour && (mm >= min + 15 || mm <= min - 30)) ||
              (hh >= hour && timeOption.getTime() > date_r.getTime()) ||
              hour < startHour
            ) {
              tempTimeConstant.push({ code: times[i], name: times[i] });
              // console.log("push:" + times[i]);
            }
          } else {
            tempTimeConstant.push({ code: times[i], name: times[i] });
          }
        }
      }
      setTimeConstant(tempTimeConstant);
      if (
        selectedDate.getDate() == cdate &&
        selectedDate.getMonth() == cmonth &&
        selectedDate.getFullYear() == cyear &&
        (hour > endHour || (hour == endHour && min >= endMinutes))
      ) {
        alert("Store is closed. You can order for next day");
        dispatch(
          setOrderProcessingDate(
            moment(selectedDate, "MM-DD-YYYY").add(1, "days")
          )
        );
        setDate(selectedDate);
      }
    }
  }, [date]);

  // let timeConstant = [
  //   { code: "", name: "Select Pickup Time" },
  //   { code: "11:00 am", name: "11:00 am" },
  //   { code: "11:15 am", name: "11:15 am" },
  //   { code: "11:30 am", name: "11:30 am" },
  //   { code: "11:30 am", name: "11:45 am" },
  //   { code: "12:00 pm", name: "12:00 pm" },
  //   { code: "12:15 pm", name: "12:15 pm" },
  //   { code: "12:30 pm", name: "12:30 pm" },
  //   { code: "01:00 pm", name: "01:00 pm" },
  //   { code: "01:15 pm", name: "01:15 pm" },
  //   { code: "01:30 pm", name: "01:30 pm" },
  //   { code: "02:00 pm", name: "02:00 pm" },
  //   { code: "02:15 pm", name: "02:15 pm" },
  //   { code: "02:30 pm", name: "02:30 pm" },
  //   { code: "03:00 pm", name: "03:00 pm" },
  //   { code: "03:15 pm", name: "03:15 pm" },
  //   { code: "03:30 pm", name: "03:30 pm" },
  //   { code: "04:00 pm", name: "04:00 pm" },
  //   { code: "04:15 pm", name: "04:15 pm" },
  //   { code: "04:30 pm", name: "04:30 pm" },
  //   { code: "05:00 pm", name: "05:00 pm" },
  //   { code: "05:30 pm", name: "05:30 pm" },
  //   { code: "06:00 pm", name: "06:00 pm" },
  //   { code: "06:30 pm", name: "06:30 pm" },
  //   { code: "07:00 pm", name: "07:00 pm" },
  //   { code: "07:30 pm", name: "07:30 pm" },
  //   { code: "08:00 pm", name: "08:00 pm" },
  //   { code: "08:30 pm", name: "08:30 pm" },
  //   { code: "09:00 pm", name: "09:00 pm" },
  // ];

  // console.log(orderProcessingDate, "first load");
  return (
    <Drawer open={open} onClose={() => setOpen(false)} anchor="bottom">
      <Box padding="1rem" height="80vh" position="relative" overflow="auto">
        <Typography
          variant="subtitle1"
          style={{ fontWeight: 800 }}
          gutterBottom
        >
          Select Service Option
        </Typography>
        <Typography gutterBottom>
          {`${orderProcessingType ? "Pickup" : "Dine-In"} - ${moment(
            orderProcessingDate
          ).format("MMMM DD, YYYY hh:mm a")}`}
        </Typography>
        <Box height="1rem" />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box
              height="75px"
              width="100%"
              display="flex"
              alignItems="center"
              borderRadius="10px"
              justifyContent="center"
              border={!orderProcessingType && "1px solid #bebebe"}
              color={orderProcessingType && "white"}
              bgcolor={orderProcessingType && "#cc3333"}
              onClick={() => dispatch(setSelectedServiceOption(true))}
            >
              <Typography variant="h6">Pickup</Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              height="75px"
              width="100%"
              display="flex"
              borderRadius="10px"
              alignItems="center"
              justifyContent="center"
              border={orderProcessingType && "1px solid #bebebe"}
              color={!orderProcessingType && "white"}
              bgcolor={!orderProcessingType && "#cc3333"}
              onClick={() => dispatch(setSelectedServiceOption(false))}
            >
              <Typography variant="h6">Dine-In</Typography>
            </Box>
          </Grid>
        </Grid>
        <Box height="1.5rem" />
        <Grid container>
          <Box width="100%">
            <Typography style={{ fontWeight: 800 }}>
              Select date of {orderProcessingType ? "pickup" : "dine-in"}
            </Typography>
            <SingleDatePicker
              focused={focused}
              numberOfMonths={1}
              date={orderProcessingDate}
              id="date_input"
              onDateChange={(date) => {
                dispatch(setOrderProcessingDate(date));
                setFocused(false);
                setDate(date);
              }}
              placeholder="Select date"
              orientation="horizontal"
              onFocusChange={(data) => {
                setFocused(data.focused);
              }}
              isDayBlocked={(day) => {
                const d = new Date(day);
                if (d.getDay() == 0) {
                  return true;
                }
              }}
            />
          </Box>
        </Grid>
        <Box height="1rem" />
        <Grid container>
          <Box width="98%">
            <Typography style={{ fontWeight: 800 }}>
              Select time of {orderProcessingType ? "pickup" : "dine-in"}
            </Typography>
            <Select
              options={timeConstant.map((time) => {
                return {
                  label: time.name,
                  value: time.code,
                };
              })}
              defaultValue={timeConstant.map((time, i) => {
                if (i === 0) {
                  return {
                    label: time.name,
                    value: time.code,
                  };
                }
              })}
              styles={{
                menu: (provided, state) => ({
                  ...provided,
                  fontSize: "1.25rem",
                }),

                singleValue: (provided, state) => ({
                  ...provided,
                  fontSize: "1.25rem",
                }),
              }}
              onChange={updateTime}
            />
          </Box>
        </Grid>
        <Box
          position="absolute"
          bottom="auto"
          left="0px"
          padding="3rem 0 0 0"
          width="100%"
        >
          <Button
            fullWidth
            elevation={0}
            style={{
              textTransform: "none",
              padding: "0rem 1rem",
              borderRadius: "10px",
              border: "1px solid #bebebe",
              backgroundColor: "white",
              fontSize: "1.25rem",
              width: "100%",
            }}
            variant="contained"
            onClick={() => setOpen(false)}
          >
            DONE
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export const TipsDrawer = ({ open, setOpen }) => {
  const { totalPriceItems } = useSelector((state) => state.cart);
  const tipsConstant = [0.1, 0.15, 0.2, 0.25];
  const dispatch = useDispatch();
  const initialValues = {
    tip: 0,
  };

  const validationSchema = Yup.object({
    tip: Yup.mixed(),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { setValue } = methods;

  const handleSubmit = (d) => {
    // console.log(d);
    dispatch(setTip(d.tip));
    setOpen(false);
  };

  return (
    <Drawer open={open} onClose={() => setOpen(false)} anchor={"bottom"}>
      <Box padding="1rem" height="70vh" position="relative">
        <Typography variant="subtitle1" style={{ fontWeight: 800 }}>
          Tips
        </Typography>
        <Toolbar />
        <Grid container spacing={2}>
          {tipsConstant.map((tip) => {
            return (
              <Grid key={tip} item xs={3}>
                <Button>
                  <Box
                    display="flex"
                    //border="1px solid black"
                    //borderRadius="50%"
                    justifyContent="center"
                    alignItems="center"
                    padding="0rem"
                    width="60px"
                    height="60px"
                    onClick={() =>
                      setValue("tip", (tip * totalPriceItems).toFixed(2))
                    }
                  >
                    <Typography>{tip * 100} %</Typography>
                  </Box>
                </Button>
              </Grid>
            );
          })}
        </Grid>
        <Toolbar />
        <Form provider={methods} onSubmit={handleSubmit}>
          <Input label="Amount $" name="tip" />
          <Box
            position="absolute"
            bottom="auto"
            left="0px"
            padding="1rem"
            width="100%"
          >
            <Button variant="contained" color="primary" fullWidth type="submit">
              Apply Tip
            </Button>
          </Box>
        </Form>
      </Box>
    </Drawer>
  );
};

const PaymentSelector = ({ setOpenUserCards }) => {
  const { selectedAddress, orderProcessingDate } = useSelector(
    (state) => state.public
  );
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      overflowX: "hidden",
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      flexWrap: "nowrap",
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: "translateZ(0)",
    },
    title: {
      color: theme.palette.primary.light,
    },
    titleBar: {
      background:
        "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
  }));

  const classes = useStyles();

  const payments = [
    "Credit Cards",
    //"Gift Cards",
    //"Rewards",
  ];

  return (
    <>
      <br />
      <Typography style={{ fontWeight: 800 }} gutterBottom>
        Types of Payment
      </Typography>

      <div className={classes.root} style={{ "overflow-x": "visible" }}>
        <ImageList
          style={{ width: "auto !important", "overflow-x": "visible" }}
          className={classes.gridList}
          cols={2.2}
        >
          {payments.map((payment) => {
            return (
              <ImageListItem
                key={payment}
                style={{
                  height: "auto",
                  margin: "0rem 6px",
                  padding: "1rem 0rem",
                  width: "auto !important",
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                  height="100px"
                  padding="1rem"
                  border="1px solid #bebebe"
                  borderRadius="5px"
                  onClick={() => {
                    if (selectedAddress !== null && orderProcessingDate) {
                      setOpenUserCards(true);
                      dispatch({
                        type: "SET_SELECTED_MODE_PAYMENT",
                        payload: payment,
                      });
                    } else {
                      if (!orderProcessingDate) {
                        alert("Please provide Service Option details!");
                      } else {
                        alert("Please provide Customer details!");
                      }
                    }
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography>{payment}</Typography>
                    {payment === "Credit Cards" && (
                      <img src={CARDICONS} style={{ maxWidth: "75px" }} />
                    )}
                    {payment === "Gift Cards" && (
                      <img src={GIFTCARDS} style={{ maxWidth: "30px" }} />
                    )}
                    {payment === "Rewards" && (
                      <img src={COIN} style={{ maxWidth: "25px" }} />
                    )}
                  </Box>
                </Box>
              </ImageListItem>
            );
          })}
        </ImageList>
        <PaymentNested />
      </div>
    </>
  );
};

export const SelectCreditCardDrawer = ({ open, setOpen, tax }) => {
  const [userCards, setUserCards] = useState([]);
  const { user } = useSelector((state) => state.public);
  const { selectedPayment } = useSelector((state) => state.cart);
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (user) {
      const unsub = db
        .collection("users")
        .doc(user.id)
        .collection("cards")
        .onSnapshot((snapshot) => {
          setUserCards(
            snapshot.docs.map((doc) => {
              return {
                ...doc.data(),
                id: doc.id,
              };
            })
          );
        });

      return unsub;
    }
  }, [user]);
  return (
    <Drawer open={open} onClose={() => setOpen(false)} anchor="bottom">
      <Box padding="1rem" height="80vh" position="relative">
        <Box height="40px" />
        <Typography style={{ fontWeight: 800 }}>{selectedPayment}</Typography>
        {userCards.map((card) => {
          return (
            <Box
              borderRadius="10px"
              border="1px solid #bebebe"
              padding="1.5rem 1rem"
              margin="1rem 0rem"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              overflow="auto"
              onClick={() => {
                dispatch(setModeOfPayment(card));
                setOpen(false);
              }}
            >
              <Box display="flex" alignItems="center">
                <input
                  type="radio"
                  checked={selectedPayment?.id === card.id}
                  style={{ marginRight: "1rem" }}
                />
                {card.CardType === "VISA" ? (
                  <img src={VISA} alt="VISA" style={{ width: "60px" }} />
                ) : card.CardType === "AMEX" ? (
                  <img alt="AMEX" src={AMEX} style={{ width: "60px" }} />
                ) : card.CardType === "M/C" ? (
                  <img
                    src={MASTERCARD}
                    alt="mastercard"
                    style={{ width: "60px" }}
                  />
                ) : (
                  <Typography>{card.CardType}</Typography>
                )}
              </Box>
              <Typography>{card.MaskedAccount}</Typography>
            </Box>
          );
        })}

        {selectedPayment == "Rewards" && <RewardsAsPayment setOpen={setOpen} />}

        {selectedPayment == "Gift Cards" && (
          <GiftCardAsPayment setOpen={setOpen} />
        )}

        {selectedPayment == "Credit Cards" && (
          <Button
            fullWidth
            onClick={() => {
              history.push("/payment-wpay/", { tax: tax });
            }}
          >
            use new card
          </Button>
        )}
      </Box>
    </Drawer>
  );
};

// Select credit cart
// redirect to form
// call iframe
// collect response
// save db
