import React from 'react';

export const AddressMap = () => {
    return (
        <div className="google-map-code">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60501432.311261296!2d153.3195193928271!3d22.250913409653112!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x877f78b2a423133b%3A0x2e0ab56463336ce1!2sRusty%20Spur%20Steakhouse!5e0!3m2!1sen!2sph!4v1648180518210!5m2!1sen!2sph" width="100%" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </div>
    );
}
 

