import "../../../App.css";
import { Container, Row, Col, Button, h5, p, h2, div } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPlayer from "react-player";
// import center from '../../../assets/css/custom.css';
import { MenuNavigation } from '../components/MenuNavigation';
import {Footer} from '../components/Footer';
import aboutimg1 from "../../../assets/images/about-img-01.jpg";
import aboutimg2 from "../../../assets/images/about-img-02.jpg";
import aboutimg3 from "../../../assets/images/about-img-03.jpg";
import React, { useEffect } from 'react'
import ReactGA from 'react-ga';
import { withRouter } from "react-router-dom";

export const About = () => {   
    
useEffect(() => {
    document.title = "About Us"
    ReactGA.initialize('UA-172332025-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
}, [])    
        
  return (
      
    <div className="About">
    <MenuNavigation/>
      <div className="HeaderImg about-info">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={8}>
              <h5 className="white" >Consuming raw or undercooked meats, poultry, seafood, shellfish, or eggs may increase your risk of food borne illness especially if you have medical conditions</h5>
              <br/> 
              <h1 className="deep-saffron">
                The Best Food in Town!
              </h1>
            </Col>
            <Col xs={8} className="">
              <h5 className="white">
                {" "}
                Established in 2015, we are a local steakhouse that serves a wide range of American fare! Come check us out! We provide a high-end experience in a country setting.
              </h5>
              <br/>
              <Button className="btn-red margin-top-10 margin-bottom-10" href="/menu" >
                  Order Now{" "}
                </Button>
            </Col>

           <col className="whitespace"></col>
          </Row>
        </Container>

       
      </div>
       <Container>
          <Row>
           <div className="col-lg-4">
                    <div class="about-info-left">
                        <h5>Highlights from the Business</h5>
                        <ul>
                            <li>Family-owned & Operated</li>
                            <li>Discounts available</li>
                            <li>Outdoor seating</li>
                            <li>Grab-and-go</li>
                            <li>Catering Services</li>
                        </ul>
                    </div>
            </div>
                  <div  className="col-lg-8">
                    <div  className="about-info-right">
                        <h1>Rusty Spur Steakhouse</h1>
                        <br/>
                        <h4> About the Business </h4>
                        <h6>Established in 2015, we are a local steakhouse that serves a wide range of American fare! Come check us out! We provide a high-end experience in a country setting.</h6>
                        
                    </div>
                    <div  className="inner-about-gallery margin-top-30 ">
                            <div className="col-lg-12">
                                <div  className="col-lg-3 col-md-3 float-left">
                                    <img src={aboutimg1} />
                                </div>
                                <div  className="col-lg-3 col-md-3 float-left">
                                    <img src={aboutimg2} />
                                </div>
                                <div  className="col-lg-3 col-md-3 float-left">
                                    <img src={aboutimg3} />
                                </div>
                            </div>
                        </div>
                </div>
            <col className="whitespace"></col> 
          </Row>
        </Container>
        <Footer/>
    </div>
  );
};

export default withRouter(About);