import ReactPlayer from "react-player";
import "../../../App.css";
import { Container, Row, Col, h5, Button, p, h2, div } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export const ContactSlider = () => {
  return (
      
<div className="ContactSlider">
      <div className="ContactHeader">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={12} sm={8} md={7} >
                <h5 className="white">
                {" "}
                Steakhouse  |  American Restaurant
              </h5>
              <h1 className="left deep-saffron">
                  Serving up the Best Steaks, Smoked Ribs, Pork, Chicken, & Burgers!
              </h1>

              <h5 className="white blankspace">
                {" "}
                Family Owned & Operated since 2010. Just a cozy small town restaurant.
              </h5>
              <Button className="btn-red whitespace margin-bottom-10" href="https://ajsliders.com/register" >
                  Join our Loyalty Program{" "}
                </Button>
            </Col>
            <Col xs={12} sm={4} md={5} className="PhonerightImg">
            </Col>

           <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
</div>
    

  );
};
