import React from "react";
// import center from "../../../assets/css/custom.css";
// import "../../../../../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../../assets/images/logo.png";
import CookieConsent from "react-cookie-consent";
import { FaHeart } from "react-icons/fa";
import {
  Container,
  Button,
  Row,
  Col,
  h5,
  p,
  h1,
  Card,
  Nav,
} from "react-bootstrap";
import { SocialConnect } from "./pages/homepage/SocialConnect";
import { useHistory } from "react-router-dom";

export const Footer = () => {
  const history = useHistory();
  return (
    <div>
      <SocialConnect />

      <div className="footer-area padding-top-40">
        <div className="footer-top d-md-block">
          <div className="container">
            <div className="row align-items-center justify-content-between padding-bottom-25">
              <div className="col-lg-3 col-md-3">
                <div className="f-logo">
                  <a href="/home">
                    {" "}
                    <img className="App-logo" src={Logo} alt="Rusty Spur Steakhouse" />
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="f-title">
                  <h4>
                    Feeling Hungry? Order Your<span> Like Food.</span>
                  </h4>
                </div>
              </div>
              <div className="col-lg-2 col-md-3">
                <Button className="btn-red" href="/menu">
                  Order Now{" "}
                </Button>
              </div>
            </div>
            <hr></hr>
          </div>
        </div>
        <div className="footer-bottom padding-top-22 padding-bottom-30">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12 margin-bottom-20">
                <div className="widget">
                  <h6>Address</h6>
                  <p>
                    Rusty Spur Steakhouse <br /> 205 E. 5th Street
                    Murdo, SD 57559
                    <br />
                    Phone: (605) 669-3290
                    <br />
                    eMail: info@visitrustyspur.com
                  </p>
                  <a href="/contact" className="f-link">
                    view location
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 margin-bottom-20">
                <div className="widget">
                  <h6>Service options:</h6>
                  <p>Dine-in · Drive-through · No delivery</p>
                  <a href="/menu" className="f-link">
                    order now
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 margin-bottom-20">
                <div className="widget">
                  <h6>Business Hours</h6>
                  <p>
                    <span>Everyday: 4:00PM - 11:00PM</span>
                      {/*<br></br>
                    <span>Sunday: CLOSED</span>*/}
                  </p>

                  <a href="tel: (605) 669-3290" className="f-link">
                    make a call
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 margin-bottom-20">
                <div className="widget heart">
                  <p>
                    &copy; {new Date().getFullYear()} Rusty Spur Steakhouse by
                    ServingIntel, made with{" "}
                    <span>
                      <FaHeart />{" "}
                    </span>{" "}
                    for a better web.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Container className="footer">
          <p className="privacy">
            <a
              href="#"
              onClick={() => {
                history.push("/privacy-policy");
              }}
            >
              Privacy Policy
            </a>
          </p>
        </Container>
      </div>
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="myAwesomeCookieName2"
        buttonClasses="btn btn-primary"
        style={{ background: "#000" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={365}
      >
        Greetings! Our website uses cookies so we may better serve you. By
        continuing to browse our site you are agreeing to our{" "}
        <span>
          <a
            href="#"
            onClick={() => {
              history.push("/terms-and-conditions");
            }}
          >
            Terms and Condition
          </a>
        </span>
      </CookieConsent>
    </div>
  );
};
