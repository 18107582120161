import ReactPlayer from "react-player";
import "../../../App.css";
import { Container, Card, Row, Col, h5, p, h2, div } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';


export const OrderSlider = () => {
useEffect(() => {
    document.title = "Menu"
    ReactGA.initialize('UA-172332025-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
}, []) 
  return (
      
<div className="OrderSlider">
      <div className="OrderHeader">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col sm={12} lg={8}>
              <h1 className="left deep-saffron">
                Great fish. Fabulous fries. Cold beer.
              </h1>
                <h5 className="white">
                {" "}
                Looking for a delicious steak 🥩? Come in and enjoy a discount as we celebrate our local ranchers! Get a souvenir cup free!
              </h5>
              <h1 className="left deep-saffron whitespace">
                Want great food and 5 star service? We got you!
              </h1>
              <h5 className="white">
                {" "}
                Anything you order on our lunch menu is gonna be out of this world! But have you paired your lunch item with our homemade beans yet?? Oh my, best beans we've ever tasted!
              </h5>                
            </Col>
            <Col sm={0} lg={4}>
    {/*<Card>
        <Card.Img src="/static/1.jpg" />
    </Card> */}
            </Col>
           <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
</div>
    

  );
};
